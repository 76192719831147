import { Component, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterOutlet } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'
import { CookieConsentModalComponent } from '@pages/tos-page/cookie-consent-modal/cookie-consent-modal.component'
import { NavigationService } from '@service/navigation/navigation.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HttpClientModule, CookieConsentModalComponent],
})
export class AppComponent {
  title = 'jv-b2b-frontend'
  private readonly navigationService = inject(NavigationService)
}
