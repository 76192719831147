import { APP_INITIALIZER, ApplicationConfig, ErrorHandler } from '@angular/core'
import { provideRouter, Router, withComponentInputBinding } from '@angular/router'

import { routes } from './app.routes'
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http'
import { Configuration } from '@core/api'
import { TokenInterceptor } from '@core/interceptors/token.interceptor'
import { CookieService } from 'ngx-cookie-service'
import { AuthService } from '@core/services/auth/auth.service'
import initializeAppFactory from './app-initializer'
import { provideHttpClientTesting } from '@angular/common/http/testing'
import * as Sentry from '@sentry/angular-ivy'

import { ResponseInterceptor } from '@core/interceptors/response.interceptor'
import { EnvConfigurationService } from '@service/auth/env-config.service'
import { DatePipe } from '@angular/common'
import { UserAnalyticsService } from '@core/services/user-analytics/user-analytics.service'
import { provideAnimations } from '@angular/platform-browser/animations'
import { CookieConsentService } from '@service/cookie-consent.service'

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideHttpClientTesting(),
    provideAnimations(),
    {
      provide: Configuration,
      useFactory: (envConfigurationService: EnvConfigurationService) =>
        new Configuration({
          basePath: envConfigurationService.getConfig()?.BACKEND_URL,
        }),
      deps: [EnvConfigurationService],
      multi: false,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [
        AuthService,
        EnvConfigurationService,
        CookieService,
        Sentry.TraceService,
        UserAnalyticsService,
        CookieConsentService,
      ],
    },
    DatePipe,
  ],
}
